'use client'

import { useEffect, useState } from 'react'

import { useRouter } from '../i18n/Navigation'
import { useTranslation } from '../i18n/useTranslation'

import { useSettingsStore } from './settings'
import { useUserStore } from './user'

export const useHydrateStores = () => {
  const [initialSwitch, setInitialSwitch] = useState(true)
  const { locale } = useTranslation()
  const router = useRouter()
  const {
    settings: { theme, language },
    setSetting,
  } = useSettingsStore()
  const [preferDark, setPreferDark] = useState(false)

  useEffect(() => {
    useUserStore.persist.rehydrate()
    useSettingsStore.persist.rehydrate()
  }, [])

  useEffect(() => {
    if (language == null && locale != null) {
      setSetting('language', locale)
    } else if (language != null && locale != null && initialSwitch) {
      setInitialSwitch(false)

      if (language != locale) {
        router.switchLocale(language)
      }
    }
  }, [initialSwitch, language, locale, router, setSetting])

  useEffect(() => {
    if (theme === 'dark' || ((theme == 'system' || theme == null) && preferDark)) {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
    } else {
      document.documentElement.classList.remove('dark')
      document.documentElement.classList.add('light')
    }
  }, [theme, preferDark])

  useEffect(() => {
    const colorScheme = window.matchMedia('(prefers-color-scheme: dark)')
    const cb = (e: MediaQueryListEvent) => setPreferDark(e.matches)
    setPreferDark(colorScheme.matches)

    colorScheme.addEventListener('change', cb)
    return () => colorScheme.removeEventListener('change', cb)
  }, [])
}
