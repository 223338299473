'use client'

import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SessionProvider } from 'next-auth/react'
import React, { useMemo, useRef } from 'react'
import { toast } from 'react-toastify'

import { useBeforeMount } from '../hooks/useBeforeMount'
import { useHandleAuthState } from '../hooks/useHandleAuthState'
import { useTranslation } from '../i18n/useTranslation'
import { useHydrateStores } from '../store'

type ProviderProps = {
  readonly children: React.ReactNode
}

const SessionHandler = () => {
  useHandleAuthState()

  return null
}

const MAX_QUERY_RETRIES = 3

export const Providers = ({ children }: ProviderProps) => {
  const { locale, t } = useTranslation()

  const toastId = useRef<string | number | null>(null)
  const client = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onSettled: () => {
            const isFetching = client.isFetching()
            if (isFetching || toastId.current === null) return

            const failedQueryKeys = client
              .getQueriesData({
                predicate: query => query.state.status === 'error',
              })
              .map(([queryKey]) => queryKey[0])
              .toString()

            toast.update(toastId.current, {
              render: `${t.general.errors.fetch} ${
                process.env.NODE_ENV === 'development' ? ` (${failedQueryKeys})` : ''
              }`,
              isLoading: false,
              type: 'error',
              autoClose: 5000,
            })
            toastId.current = null
          },
        }),
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: Error) => {
              const loadingMessage = `${t.general.errors.retryFetch}${
                process.env.NODE_ENV === 'development' ? ` (${error.message})` : ''
              }`
              if (toastId.current === null) toastId.current = toast.loading(loadingMessage)
              else
                toast.update(toastId.current, {
                  render: loadingMessage,
                })

              return failureCount < MAX_QUERY_RETRIES
            },
          },
        },
      }),
    [t.general.errors.fetch, t.general.errors.retryFetch],
  )

  useHydrateStores()
  useBeforeMount(() => document.documentElement.setAttribute('lang', locale))

  return (
    <QueryClientProvider client={client}>
      <SessionProvider>
        {children}
        <SessionHandler />
      </SessionProvider>
    </QueryClientProvider>
  )
}
