import { useEffect, useState } from 'react'

export const useTheme = (): 'light' | 'dark' => {
  const [isDarkTheme, setIsDarkTheme] = useState(false)

  useEffect(() => {
    const attrObserver = new MutationObserver(mutations => {
      mutations.forEach(mu => {
        if (mu.type === 'attributes' && mu.attributeName === 'class') {
          const prevDark = mu.oldValue?.split(' ').includes('dark') ?? false
          const newDark = document.documentElement.classList.contains('dark')

          if (prevDark !== newDark) {
            setIsDarkTheme(newDark)
          }
        }
      })
    })

    attrObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
      attributeOldValue: true,
    })

    setIsDarkTheme(document.documentElement.classList.contains('dark'))

    return () => attrObserver.disconnect()
  }, [])

  return isDarkTheme ? 'dark' : 'light'
}
