import { type User } from 'next-auth'
import { signOut, useSession } from 'next-auth/react'
import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useTranslation } from '../i18n/useTranslation'
import { useUserStore } from '../store/user'

export const useSignOut = () => {
  const { setUser } = useUserStore()

  return useCallback(() => {
    setUser({ loading: false })
    signOut()
  }, [setUser])
}

export const useHandleAuthState = () => {
  const { t } = useTranslation()
  const session = useSession()
  const { setUser, user } = useUserStore()
  const signOutUser = useSignOut()

  useEffect(() => {
    if (session.status !== 'loading') {
      if (session.status === 'authenticated') {
        if (session.data.error) {
          // Handle auth errors
          if (session.data.error === 'RefreshAccessTokenError') {
            toast.error(t.general.errors.sessionRenew)
          }
          signOutUser()
        } else if (session.data.user && session.data.user.id != user.id) {
          // Setting the user, if changed
          setUser({ ...(session.data.user as User), loading: false })
        } else {
          setUser({ ...user, loading: false })
        }
      } else {
        // Setting the user to undefined, if not loading
        setUser({ loading: false })
      }
    } else if (user?.id != null) {
      // Setting the user to loading, if not authenticated
      setUser({ ...user, loading: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])
}
