import { useRef } from 'react'

export const useBeforeMount = <T>(cb: () => T): T => {
  const isMountedRef = useRef(false)
  const resultRef = useRef<T>()

  if (!isMountedRef.current && typeof window !== 'undefined') {
    resultRef.current = cb()
  }

  isMountedRef.current = true

  return resultRef.current!
}
