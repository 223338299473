import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { type AVAILABLE_LOCALES } from '../i18n/useTranslation'

type Settings = Partial<{
  language: (typeof AVAILABLE_LOCALES)[number]
  theme: 'light' | 'dark' | 'system'
}>

export type SettingsStore = {
  settings: Settings
  setSetting: <T extends keyof Settings>(key: T, value: Settings[T]) => void
}

export const useSettingsStore = create<SettingsStore>()(
  persist(
    set => ({
      settings: {
        theme: 'system',
      },
      setSetting: (key, value) => set(prev => ({ settings: { ...prev.settings, [key]: value } })),
    }),
    {
      name: 'eva_settings',
      skipHydration: true,
      partialize: state => ({ settings: state.settings }),
    },
  ),
)
