'use client'

import { ToastContainer } from 'react-toastify'

import './globals.css'
import { Providers } from '../components/Providers'
import { useTheme } from '../hooks/useTheme'

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const theme = useTheme()

  return (
    // eslint-disable-next-line jsx-a11y/html-has-lang
    <html suppressHydrationWarning>
      <head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          try {
            const themeSettings = ('eva_settings' in localStorage) ? JSON.parse(localStorage.eva_settings).state.settings.theme ?? 'system' : 'system'
            if ((themeSettings === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) || themeSettings === 'dark' ) {
              document.documentElement.classList.add('dark')
              document.documentElement.classList.remove('light')
            } else {
              document.documentElement.classList.remove('dark')
              document.documentElement.classList.add('light')
            }
          } catch (_) {}
        `,
          }}
        />
      </head>
      <body>
        <Providers>
          <div className='h-screen w-screen'>{children}</div>
        </Providers>

        <ToastContainer position='bottom-right' hideProgressBar theme={theme} />
      </body>
    </html>
  )
}
